// Styles
import "../styles/fullscreenView.css";

// React Lottie
import Lottie from "lottie-react";

// Icons and Images
import LoadingEcommerce from "../assets/loading/LoadingEcommerce.json";

const Loader = ({height}) => {
  return (
    <div
      className="loadingAnimationContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Lottie
        animationData={LoadingEcommerce}
        loop={true}
        style={{ height }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h1 style={{ color: "black", fontWeight: "600" }}>Loading...</h1>
      </div>
    </div>
  )
}

export default Loader