import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Styles
import "../../styles/shoppingCart.css";

// Images and Icons
import DeleteProductIcon from "../../assets/itemImagesAndIcons/DeleteProductIcon.png";
import CheckedSizeConvertionIcon from "../../assets/itemImagesAndIcons/CheckedSizeConvertionIcon.png";
import UncheckedSizeConvertionIcon from "../../assets/itemImagesAndIcons/UncheckedSizeConvertionIcon.png";
import ValidIcon from "../../assets/shoopingCartImagesAndIcons/ValidIcon.png";
import PrintIcon from "../../assets/shoopingCartImagesAndIcons/PrintIcon.png";
import CardNumberEmptyIcon from "../../assets/shoopingCartImagesAndIcons/CardNumberEmptyIcon.png";
import CardHolderNumberEmptyIcon from "../../assets/shoopingCartImagesAndIcons/CardHolderNumberEmptyIcon.png";
import CardNumberIcon from "../../assets/shoopingCartImagesAndIcons/CardNumberIcon.png";
import CardHolderNumberIcon from "../../assets/shoopingCartImagesAndIcons/CardHolderNumberIcon.png";
import EmptyShoppingCartIcon from "../../assets/shoopingCartImagesAndIcons/EmptyShoppingCartIcon.png";
import ArrowUpIcon from "../../assets/navbarIcons/ArrowUpIcon.png";
import ArrowDownIcon from "../../assets/navbarIcons/ArrowDownIcon.png";
import BackToPreviousPageIcon from "../../assets/myProfileIcons/BackToPreviousPageIcon.png"

// Components
import {
  InputDropdown,
  InputDropdownAsync,
  InputText,
  InputTextArea,
  PhoneNumberInput,
} from "../../components/Inputs";

import GoogleMaps from "../../components/GoogleMaps";

// Core UI Icons
import * as icon from "@coreui/icons";
import CIcon from "@coreui/icons-react";

// Helpers
import { createCountriesOptions } from "../../helpers/getCountries";

// Apis
import {
  getAllCountries,
  getCities,
  getDetailCity,
  getDetailProvince,
  getDetailSubDistrict,
  getProvinces,
  getSubDistricts,
} from "../../api/countries";
import {
  addCartQty,
  calculateCartOverview,
  deleteProductFromCart,
  getShippingOptions,
  reduceCartQty,
} from "../../api/carts";
import {
  getUserShippingAddresses,
  createNewShippingAddress,
  editShippingAddress,
} from "../../api/shippingAddresses";
import { getAllShippingMethods } from "../../api/shippingMethods";
import { getAllPaymentChannel } from "../../api/payments";
import { createTransaction } from "../../api/transactions";
import { checkPromo } from "../../api/promos";

// Helpers
import { timer } from "../../helpers/timer";
import { formUrlQuery } from "../../helpers/format-queries";
import { handleFetchUsersShoppingCarts } from "../../helpers/handleFetchUsersShoppingCarts";
import { formatPrice } from "../../helpers/format-price";

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authenticationModal = useSelector((state) => state.authenticationModal);
  const userDetail = useSelector((state) => state.userDetail);
  const isMobileSize = useSelector((state) => state.isMobileSize);
  const searchState = useSelector((state) => state.searchState);
  const shoppingCarts = useSelector((state) => state.shoppingCarts);
  const [isOpenSuccessPaymentComponent, setIsOpenSuccessPaymentComponent] =
    useState(false);

  const [isOpenSlideTwo, setIsOpenSlideTwo] = useState(false);

  // slide one states
  const [totalPrice, setTotalPrice] = useState({
    promoCode: "",
    couponDiscount: 0,
    totalItems: 0,
  });
  const [validPromoCode, setValidPromoCode] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [isSelectAllCarts, setIsSelectAllCarts] = useState(false);
  const [totalPriceBeforeDiscount, setTotalPriceBeforeDiscount] = useState({
    totalPrice: 0,
    totalProductTax: 0
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState([]);
  const [userShippingAddressOptions, setUserShippingAddressOptions] = useState([]);
  const [isEditableShippingAddressFields, setIsEditableShippingAddressFields] =
    useState(false);
  const [userShippingAddressValue, setUserShippingAddressValue] =
    useState(null);

  const [contactInfoState, setContactInfoState] = useState({
    firstName: "",
    lastName: "",
    number: "",
    icon: "",
    phone_code: "",
    country_id: "",
    isOpenDropdownCountry: false,
  });

  // Gmaps state
  const [latLng, setLatLng] = useState({ lat: -6.1753917, lng: 106.8271533 }) // Pinpoint Location DKI Jakarta
  const [mLatLng, setMLatLng] = useState({ mLat: -6.1753917, mLng: 106.8271533 })  // Pinpoint Location DKI Jakarta
  const [locationDetails, setLocationDetails] = useState('')

  const [shippingAddressState, setShippingAddressState] = useState({
    firstName: "",
    lastName: "",
    addressDetails: "",
    city: null,
    province: null,
    subDistrict: null,
    postalCode: "",
    number: "",
    icon: icon.cifId,
    phone_code: "+62",
    country_id: 104,
    isOpenDropdownCountry: false,
    addressName: "",
    additionalNotes: "",
  });

  const [paymentChannels, setPaymentChannels] = useState([]);
  const [paymentChannelId, setPaymentChannelId] = useState(null);
  const fetchPaymentChannel = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getAllPaymentChannel();
    if (result && result.data) {
      let finalPaymentChannels = result.data;
      finalPaymentChannels =
        finalPaymentChannels &&
          Array.isArray(finalPaymentChannels) &&
          finalPaymentChannels.length > 0
          ? finalPaymentChannels.map((paymentChannel) => {
            paymentChannel.checked = false;
            return paymentChannel;
          })
          : [];
      setPaymentChannels(finalPaymentChannels);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const fetchProvinces = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getProvinces();
    if (result && result.data) {
      let finalProvinceOptions = result.data;
      finalProvinceOptions = finalProvinceOptions.map((prov) => {
        let objProv = {
          label: prov.province,
          value: prov.province_id,
        };
        return objProv;
      });
      setProvinceOptions(finalProvinceOptions);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const fetchCities = async (provinceId) => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getCities(provinceId);
    if (result && result.data) {
      let finalCityOptions = result.data;
      finalCityOptions = finalCityOptions.map((city) => {
        let objCity = {
          label: city.city_name,
          value: city.city_id,
          postal_code: city.postal_code,
        };
        return objCity;
      });
      setCityOptions(finalCityOptions);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const fetchSubDistricts = async (cityId) => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getSubDistricts(cityId);
    if (result && result.data) {
      let finalSubDistrictOptions = result.data;
      finalSubDistrictOptions = finalSubDistrictOptions.map((subDistrict) => {
        let objSubDistrict = {
          label: subDistrict.subdistrict_name,
          value: subDistrict.subdistrict_id,
        };
        return objSubDistrict;
      });
      setSubDistrictOptions(finalSubDistrictOptions);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  // slide two states
  const [availableShippingMethods, setAvailableShippingMethods] = useState([]);
  const [childrenShippingMethods, setChildrenShippingMethods] = useState([]);
  const fetchAvailableShippingMethods = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getAllShippingMethods();
    if (result && result.data) {
      const { data } = result || {};
      if (data && Array.isArray(data) && data.length > 0) {
        let arrAvailabelShippingMethods = data;
        arrAvailabelShippingMethods = arrAvailabelShippingMethods.map(
          (shippingMethod) => {
            shippingMethod.checked = false;
            return shippingMethod;
          }
        );
        setAvailableShippingMethods(arrAvailabelShippingMethods);
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  useEffect(() => {
    if (isOpenSlideTwo) {
      fetchAvailableShippingMethods();
      window.scrollTo(0, 0)
    }
  }, [isOpenSlideTwo]);

  // const [isOpenPaymentDetailForm, setIsOpenPaymentDetailForm] = useState(false);
  // const [invalidCardDetails, setInvalidCardDetails] = useState(false);
  // const [invalidCVCPaymmentDetail, setInvalidCVCPaymmentDetail] =
  //   useState(false);
  // const [invalidExpireDate, setInvalidExpireDate] = useState(false);
  // const [cardDetailsPaymentDetail, setCardDetailsPaymentDetail] = useState("");
  // const [
  //   cardHolderNameDetailsPaymentDetail,
  //   setCardHolderNameDetailsPaymentDetail,
  // ] = useState("");

  const [transactionState, setTransactionState] = useState(null);

  // CIS = Contact Info Validation State
  const [invalidCISfirstName, setInvalidCISfirstName] = useState(false);
  const [invalidCISlastName, setInvalidCISlastName] = useState(false);
  const [invalidCISphoneNumber, setInvalidCISphoneNumber] = useState(false);
  const [invalidCIScountryId, setInvalidCIScountryId] = useState(false);

  // SAS = Shipping Address Validation State
  const [invalidSASfirstName, setInvalidSASfirstName] = useState(false);
  const [invalidSASlastName, setInvalidSASlastName] = useState(false);
  const [invalidSASaddressDetails, setInvalidSASaddressDetails] =
    useState(false);
  const [invalidSASstreetAddress, setInvalidSASstreetAddress] =
    useState(false);
  const [invalidSASProvince, setInvalidSASProvince] = useState(false);
  const [invalidSASCity, setInvalidSASCity] = useState(false);
  const [invalidSASSubDistrict, setInvalidSASSubDistrict] = useState(false);
  const [invalidSASpostalCode, setInvalidSASpostalCode] = useState(false);
  const [invalidSAScountryId, setInvalidSAScountryId] = useState(false);
  const [invalidSASphoneNumber, setInvalidSASphoneNumber] = useState(false);
  const [invalidSASaddressName, setInvalidSASaddressName] = useState(false);

  const fetchAllCountries = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const response = await getAllCountries();
    if (response && response.data) {
      const { data } = response;
      if (data && Array.isArray(data) && data.length > 0) {
        setCountryOptions(createCountriesOptions(data));
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleClickPlusAndMinusBtnOnCart = async (flagger, cartId) => {
    let result;
    for (let el = 0; el < shoppingCarts.length; el++) {
      const { id, product: { qty } = {}, combination } = shoppingCarts[el];
      let finalQty = combination ? combination.qty : qty
      if (cartId === id) {
        if (flagger === "plus") {
          if (
            finalQty &&
            shoppingCarts[el] &&
            (shoppingCarts[el].qty || shoppingCarts[el].qty === 0) &&
            shoppingCarts[el].qty + 1 <= finalQty
          ) {
            const body = { cart_id: id, added_qty: 1 };
            result = await addCartQty(body);
          }
        } else {
          if (
            shoppingCarts[el] &&
            shoppingCarts[el].qty &&
            shoppingCarts[el].qty > 0
          ) {
            const body = { cart_id: id, reduced_qty: 1 };
            result = await reduceCartQty(body);
          }
        }
      }
    }
    if (result && result.status && result.status === 200) {
      handleFetchUsersShoppingCarts(searchState, dispatch);
    }
  };

  useEffect(() => {
    if (userDetail) {
      fetchAllCountries();
      fetchProvinces();
      fetchPaymentChannel();
      handleFetchUserShippingAddresses();
    }
  }, [userDetail]);

  useEffect(() => {
    if (shoppingCarts?.length > 0) {
      const isAllChecked = shoppingCarts?.map(item => item?.checked)?.every(value => value === true)
      setIsSelectAllCarts(isAllChecked)
    }
  }, [shoppingCarts])

  const handleFetchUserShippingAddresses = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getUserShippingAddresses();
    if (result && result.data) {
      const { data } = result || {};
      let finalShippingAddressOptions = [];
      if (data && Array.isArray(data) && data.length > 0) {
        data.forEach((shippingAddress) => {
          finalShippingAddressOptions.push({
            label: `${shippingAddress?.address_name || `${shippingAddress?.full_name}'s address`}`,
            value: shippingAddress,
          });
        });
        finalShippingAddressOptions.push({
          label: `Create new address`,
          value: null,
        })
      }
      setUserShippingAddressOptions(finalShippingAddressOptions);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleSetPriceBeforeDiscount = () => {
    if (!totalPriceBeforeDiscount?.totalPrice && !totalPriceBeforeDiscount?.totalProductTax) {
      setTotalPriceBeforeDiscount({
        totalPrice: totalPrice?.product_price,
        totalProductTax: totalPrice?.product_tax
      })
    }
  }

  const handleCheckPromoCode = async () => {
    const body = { promo_code: promoCode, products: orderSummaryParams?.products };
    handleSetPriceBeforeDiscount()
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await checkPromo(body);
    if (result && result.data) {
      calculateOrderSummary(searchState?.currencyVal?.id, orderSummaryParams, promoCode)
      setValidPromoCode(true);
    } else {
      setValidPromoCode(false);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleOnMouseHover = (flagger, value, cartId) => {
    let finalShoppingCarts = shoppingCarts;
    finalShoppingCarts = finalShoppingCarts.map((cart) => {
      const { id } = cart || {};
      if (cartId === id) {
        if (flagger === "plus") {
          cart.onMouseOverPlus = value;
        } else {
          cart.onMouseOverMinus = value;
        }
      }
      return cart;
    });
    dispatch({
      type: "SET_USERS_CART",
      shoppingCarts: finalShoppingCarts,
    });
  };

  const handleSelectAllCartItems = (value) => {
    let finalShoppingCarts = shoppingCarts;
    finalShoppingCarts = finalShoppingCarts.map((cart) => {
      return { ...cart, checked: value };
    });
    dispatch({
      type: "SET_USERS_CART",
      shoppingCarts: finalShoppingCarts,
    });
    setIsSelectAllCarts(value);
  };

  const handleOnClickEachCheckbox = (value, idx) => {
    let finalShoppingCarts = shoppingCarts;
    let isAllChecked = true
    finalShoppingCarts = finalShoppingCarts.map((cart, index) => {
      if (index === idx) {
        cart = { ...cart, checked: value };
      }
      if (cart.checked === false) {
        isAllChecked = false
      }
      return cart;
    });
    dispatch({
      type: "SET_USERS_CART",
      shoppingCarts: finalShoppingCarts,
    });
    setIsSelectAllCarts(isAllChecked);
  };

  const handleOnDeleteEachItem = async (id) => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await deleteProductFromCart(id);
    if (result && result.data && result.data.is_deleted) {
      // update users cart
      handleFetchUsersShoppingCarts(searchState, dispatch);

      const beforeFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: true,
            msg: "Success delete item from your cart.",
            show: true,
          },
        });
      };
      const afterFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: false,
            msg: "",
            show: false,
          },
        });
      };
      timer(beforeFunction, afterFunction, 3000);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleDeleteAllItemInShoppingCart = async () => {
    if (
      shoppingCarts &&
      Array.isArray(shoppingCarts) &&
      shoppingCarts.length > 0
    ) {
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: true,
      });
      shoppingCarts.forEach(async (cart) => {
        if (cart && cart.id) {
          await deleteProductFromCart(cart.id);
        }
      });
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: false,
      });
      dispatch({
        type: "SET_USERS_CART",
        shoppingCarts: [],
      });
      dispatch({
        type: "SET_TOTAL_USERS_CART",
        totalUsersCart: null,
      });
      const beforeFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: true,
            msg: "Success delete all items from your cart.",
            show: true,
          },
        });
      };
      const afterFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: false,
            msg: "",
            show: false,
          },
        });
      };
      timer(beforeFunction, afterFunction, 3000);
    }
  };

  const handleOnClickPaymentDetailCheckbox = (value, idx) => {
    let finalPaymentChannels = paymentChannels;
    let checkedPaymentChannelId = paymentChannelId;

    finalPaymentChannels = finalPaymentChannels.map((paymentChannel, index) => {
      const {
        id,
        //  name
      } = paymentChannel || {};
      if (index === idx) {
        paymentChannel = { ...paymentChannel, checked: !value };
        if (value === false) {
          checkedPaymentChannelId = id;
          // if (name === "Credit Card") {
          //   openPaymentDetailForm = true;
          // }
        } else {
          checkedPaymentChannelId = null;
        }
      } else {
        paymentChannel = { ...paymentChannel, checked: false };
      }
      return paymentChannel;
    });

    setPaymentChannelId(checkedPaymentChannelId);
    // setIsOpenPaymentDetailForm(openPaymentDetailForm);
    setPaymentChannels(finalPaymentChannels);
    setOrderSummaryParams(prev => ({
      ...prev,
      mpayment_channel_id: checkedPaymentChannelId
    }))
  };

  const getChildrenShippingMethod = async (shippingMethod) => {
    const { identifier } = shippingMethod || {};
    let arrProducts =
      shoppingCarts && Array.isArray(shoppingCarts) && shoppingCarts.length > 0
        ? shoppingCarts.map((cart) => {
          const { product, qty, combination_id, product_unit_price } = cart || {};
          return { id: product.id, qty, combination_id, product_unit_price };
        })
        : [];
    if (arrProducts.length > 0) {
      const body = {
        products: arrProducts,
        shipping_address_id: shippingAddressState.shippingAddressId,
        shipping_method_identifier: identifier,
      };
      let queries = {};
      if (
        searchState &&
        searchState.currencyVal &&
        searchState.currencyVal.id
      ) {
        queries.currency_id = searchState.currencyVal.id;
      }
      queries = formUrlQuery(queries);
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: true,
      });
      // const result = await getShippingOptions(queries, body);
      await getShippingOptions(queries, body)
        .then((result) => {
          if (result && result.data) {
            const { data } = result || {};
            let finalData = [];
            if (data && Array.isArray(data) && data.length > 0) {
              finalData = data.map((opt) => {
                opt.isSelected = false;
                return opt;
              });
            }
            setChildrenShippingMethods(finalData);
          }
        })
        .catch((err) => {
          setChildrenShippingMethods([]);
        })

        dispatch({
          type: "SET_IS_LOADING",
          isLoading: false,
        });
      
      // if (result && result.data) {
      //   const { data } = result || {};
      //   let finalData = [];
      //   if (data && Array.isArray(data) && data.length > 0) {
      //     finalData = data.map((opt) => {
      //       opt.isSelected = false;
      //       return opt;
      //     });
      //   }
      //   setChildrenShippingMethods(finalData);
      // }
      // dispatch({
      //   type: "SET_IS_LOADING",
      //   isLoading: false,
      // });
    }
  };

  const handleOnClickAvailableShippingCheckbox = (value, idx) => {
    let finalAvailableShippingMethods = availableShippingMethods;
    finalAvailableShippingMethods = finalAvailableShippingMethods.map(
      (availableShipping, index) => {
        if (index === idx) {
          if (value === false) {
            getChildrenShippingMethod(availableShipping);
          }
          availableShipping = { ...availableShipping, checked: !value };
        } else {
          availableShipping = { ...availableShipping, checked: false };
        }
        return availableShipping;
      }
    );
    setAvailableShippingMethods(finalAvailableShippingMethods);
  };

  const handleOnChangePhoneNumber = (
    value,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    let parts = value.split(" ");
    let withoutCountryCode;

    if (phoneNumberState && phoneNumberState.phone_code) {
      withoutCountryCode = parts[1];
    } else {
      withoutCountryCode = parts.join(" ");
    }

    setterPhoneNumber({
      ...phoneNumberState,
      number: withoutCountryCode,
    });
  };

  const handleChangePhoneNumberDropdown = (
    val,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    const { country_code, phone_code, id } = (val && val.value) || {};
    let countryIcon = "";
    if (country_code) {
      countryIcon = icon[`cif${country_code}`];
    }
    setterPhoneNumber({
      ...phoneNumberState,
      icon: countryIcon,
      phone_code: phone_code,
      country_id: id,
      isOpenDropdownCountry: false,
    });
  };

  const handleOnChangeUseInformation = async (value) => {
    if (value) {
      const {
        profile: {
          first_name,
          last_name,
          phone_number,
          country: { id, phone_code, country_code } = {},
        } = {},
      } = userDetail || {};
      let contact_info_first_name = "";
      let contact_info_last_name = "";
      let contact_info_countryIcon = "";
      let contact_info_number = "";
      let contact_info_phone_code = "";
      let contact_info_country_id = "";
      if (first_name) {
        contact_info_first_name = first_name;
      }
      if (last_name) {
        contact_info_last_name = last_name;
      }
      if (country_code) {
        contact_info_countryIcon = icon[`cif${country_code}`];
      }
      if (phone_number) {
        contact_info_number = phone_number;
      }
      if (phone_code) {
        contact_info_phone_code = phone_code;
      }
      if (id) {
        contact_info_country_id = id;
      }
      setContactInfoState({
        ...contactInfoState,
        firstName: contact_info_first_name,
        lastName: contact_info_last_name,
        icon: contact_info_countryIcon,
        phone_code: contact_info_phone_code,
        number: contact_info_number,
        country_id: contact_info_country_id,
      });
    } else {
      setContactInfoState({
        firstName: "",
        lastName: "",
        number: "",
        icon: "",
        phone_code: "",
        country_id: "",
        isOpenDropdownCountry: false,
      });
    }
    // if (form === "contact info") {
    //   if (value) {
    //     const {
    //       profile: {
    //         first_name,
    //         last_name,
    //         phone_number,
    //         country: { id, phone_code, country_code } = {},
    //       } = {},
    //     } = userDetail || {};
    //     let contact_info_first_name = "";
    //     let contact_info_last_name = "";
    //     let contact_info_countryIcon = "";
    //     let contact_info_number = "";
    //     let contact_info_phone_code = "";
    //     let contact_info_country_id = "";
    //     if (first_name) {
    //       contact_info_first_name = first_name;
    //     }
    //     if (last_name) {
    //       contact_info_last_name = last_name;
    //     }
    //     if (country_code) {
    //       contact_info_countryIcon = icon[`cif${country_code}`];
    //     }
    //     if (phone_number) {
    //       contact_info_number = phone_number;
    //     }
    //     if (phone_code) {
    //       contact_info_phone_code = phone_code;
    //     }
    //     if (id) {
    //       contact_info_country_id = id;
    //     }
    //     setContactInfoState({
    //       ...contactInfoState,
    //       firstName: contact_info_first_name,
    //       lastName: contact_info_last_name,
    //       icon: contact_info_countryIcon,
    //       phone_code: contact_info_phone_code,
    //       number: contact_info_number,
    //       country_id: contact_info_country_id,
    //     });
    //   } else {
    //     setContactInfoState({
    //       firstName: "",
    //       lastName: "",
    //       number: "",
    //       icon: "",
    //       phone_code: "",
    //       country_id: "",
    //       isOpenDropdownCountry: false,
    //     });
    //   }
    // } else {
    //   if (value) {
    //     dispatch({
    //       type: "SET_IS_LOADING",
    //       isLoading: true,
    //     });
    //     const result = await getUserShippingAddresses();
    //     if (result && result.data) {
    //       const { data } = result || {};
    //       if (data && Array.isArray(data) && data.length > 0) {
    //         const {
    //           first_name,
    //           last_name,
    //           address_details,
    //           province_id,
    //           city_id,
    //           subdistrict_id,
    //           postal_code,
    //           phone_number,
    //           country,
    //         } = data[0];
    //         let countryIcon;
    //         if (country && country.country_code) {
    //           countryIcon = icon[`cif${country.country_code}`];
    //         }
    //         let provinceValue = null;
    //         let cityValue = null;
    //         let subDistrictValue = null;
    //         if (province_id) {
    //           const foundProvince = await getDetailProvince(province_id);
    //           if (foundProvince && foundProvince.data) {
    //             const { data: { province, province_id } = {} } = foundProvince;
    //             provinceValue = {
    //               value: province_id,
    //               label: province,
    //             };
    //           }
    //         }
    //         if (city_id) {
    //           const foundCity = await getDetailCity(city_id);
    //           if (foundCity && foundCity.data) {
    //             const { data: { city_name, city_id } = {} } = foundCity;
    //             cityValue = {
    //               value: city_id,
    //               label: city_name,
    //             };
    //           }
    //         }
    //         if (subdistrict_id) {
    //           const foundSubDistrict = await getDetailSubDistrict(
    //             subdistrict_id
    //           );
    //           if (foundSubDistrict && foundSubDistrict.data) {
    //             const { data: { subdistrict_name, subdistrict_id } = {} } =
    //               foundSubDistrict;
    //             subDistrictValue = {
    //               value: subdistrict_id,
    //               label: subdistrict_name,
    //             };
    //           }
    //         }
    //         setShippingAddressState({
    //           firstName: first_name,
    //           lastName: last_name,
    //           addressDetails: address_details,
    //           city: cityValue,
    //           province: provinceValue,
    //           subDistrict: subDistrictValue,
    //           postalCode: postal_code,
    //           number: phone_number,
    //           icon: countryIcon,
    //           phone_code: country && country.phone_code,
    //           country_id: country && country.id,
    //           isOpenDropdownCountry: false,
    //         });
    //       } else {
    //         const {
    //           profile: { first_name, last_name, phone_number, country },
    //         } = userDetail;
    //         let countryIcon;
    //         if (country && country.country_code) {
    //           countryIcon = icon[`cif${country.country_code}`];
    //         }
    //         setShippingAddressState({
    //           firstName: first_name,
    //           lastName: last_name,
    //           number: phone_number,
    //           icon: countryIcon,
    //           country_id: country && country.id,
    //           addressDetails: "",
    //           city: null,
    //           province: null,
    //           subDistrict: null,
    //           postalCode: "",
    //           phone_code: "",
    //           isOpenDropdownCountry: false,
    //         });
    //       }
    //     }
    //     dispatch({
    //       type: "SET_IS_LOADING",
    //       isLoading: false,
    //     });
    //   } else {
    //     setShippingAddressState({
    //       firstName: "",
    //       lastName: "",
    //       addressDetails: "",
    //       city: null,
    //       province: null,
    //       subDistrict: null,
    //       postalCode: "",
    //       number: "",
    //       icon: "",
    //       phone_code: "",
    //       country_id: "",
    //       isOpenDropdownCountry: false,
    //     });
    //   }
    // }
  };

  const handleOnChangeDropdownProvinceCityAndSubDistrict = (val, flagger) => {
    if (flagger) {
      if (flagger === "province") {
        setShippingAddressState({
          ...shippingAddressState,
          province: val,
        });
        fetchCities(val.value);
      }
      if (flagger === "city") {
        setShippingAddressState({
          ...shippingAddressState,
          city: val,
          postalCode: val.postal_code,
        });
        fetchSubDistricts(val.value);
      }
      if (flagger === "sub-district") {
        setShippingAddressState({
          ...shippingAddressState,
          subDistrict: val,
        });
      }
    }
  };

  const handleValidationClickContinueToPayment = () => {
    // // CIS = Contact Info State
    // let invalidCISfirstName = false;
    // let invalidCISlastName = false;
    // let invalidCISphoneNumber = false;
    // let invalidCIScountryId = false;

    // SAS = Shipping Address State
    let invalidSASfirstName = false;
    let invalidSASlastName = false;
    let invalidSASaddressDetails = false;
    let invalidSASstreetAddress = false;
    let invalidSASProvince = false;
    let invalidSASCity = false;
    let invalidSASSubDistrict = false;
    let invalidSASpostalCode = false;
    let invalidSAScountryId = false;
    let invalidSASphoneNumber = false;
    let invalidSASaddressName = false;

    // if (!contactInfoState.firstName) {
    //   invalidCISfirstName = true;
    // }
    // if (!contactInfoState.lastName) {
    //   invalidCISlastName = true;
    // }
    // if (!contactInfoState.number) {
    //   invalidCISphoneNumber = true;
    // }
    // if (!contactInfoState.country_id) {
    //   invalidCIScountryId = true;
    // }
    if (!shippingAddressState.firstName) {
      invalidSASfirstName = true;
    }
    if (!shippingAddressState.lastName) {
      invalidSASlastName = true;
    }
    if (!shippingAddressState.addressDetails) {
      invalidSASaddressDetails = true;
    }
    if (!locationDetails) {
      invalidSASstreetAddress = true;
    }
    if (shippingAddressState) {
      if (!shippingAddressState.province) {
        invalidSASProvince = true;
      }
      if (!shippingAddressState.city) {
        invalidSASCity = true;
      }
      if (!shippingAddressState.subDistrict) {
        invalidSASSubDistrict = true;
      }
    }
    if (!shippingAddressState.postalCode) {
      invalidSASpostalCode = true;
    }
    if (!shippingAddressState.country_id) {
      invalidSAScountryId = true;
    }
    if (!shippingAddressState.number) {
      invalidSASphoneNumber = true;
    }
    if (!shippingAddressState.addressName) {
      invalidSASaddressName = true
    }

    // setInvalidCISfirstName(invalidCISfirstName);
    // setInvalidCISlastName(invalidCISlastName);
    // setInvalidCISphoneNumber(invalidCISphoneNumber);
    // setInvalidCIScountryId(invalidCIScountryId);
    setInvalidSASfirstName(invalidSASfirstName);
    setInvalidSASlastName(invalidSASlastName);
    setInvalidSASaddressDetails(invalidSASaddressDetails);
    setInvalidSASstreetAddress(invalidSASstreetAddress);
    setInvalidSASProvince(invalidSASProvince);
    setInvalidSASCity(invalidSASCity);
    setInvalidSASSubDistrict(invalidSASSubDistrict);
    setInvalidSASpostalCode(invalidSASpostalCode);
    setInvalidSAScountryId(invalidSAScountryId);
    setInvalidSASphoneNumber(invalidSASphoneNumber);
    setInvalidSASaddressName(invalidSASaddressName)

    if (
      // invalidCISfirstName !== true &&
      // invalidCISlastName !== true &&
      // invalidCISphoneNumber !== true &&
      // invalidCIScountryId !== true &&
      invalidSASfirstName !== true &&
      invalidSASlastName !== true &&
      invalidSASaddressDetails !== true &&
      invalidSASstreetAddress !== true &&
      invalidSASProvince !== true &&
      invalidSASCity !== true &&
      invalidSASSubDistrict !== true &&
      invalidSASpostalCode !== true &&
      invalidSAScountryId !== true &&
      invalidSASphoneNumber !== true &&
      invalidSASaddressName !== true
    ) {
      handleOnSaveNewAddress()
      return true;
    } else {
      return false;
    }
  };

  const handleOnSaveNewAddress = async () => {
    const body = {
      province_id: shippingAddressState.province?.value,
      city_id: shippingAddressState.city?.value,
      subdistrict_id: shippingAddressState.subDistrict?.value,
      phone_number: shippingAddressState.number,
      address_details: shippingAddressState.addressDetails,
      postal_code: shippingAddressState.postalCode,
      first_name: shippingAddressState.firstName,
      last_name: shippingAddressState.lastName,
      country_id: shippingAddressState.country_id,
      address_name: shippingAddressState.addressName,
      city_label: shippingAddressState.city?.label,
      province_label: shippingAddressState.province?.label,
      subdistrict_label: shippingAddressState.subDistrict?.label,
      street_address: locationDetails,
      latitude_marker: mLatLng?.mLat,
      longitude_marker: mLatLng?.mLng,
      latitude_map: latLng?.lat,
      longitude_map: latLng?.lng
    };

    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });

    let dataAddress = {}
    if (shippingAddressState.shippingAddressId) {
      body.address_id = shippingAddressState.shippingAddressId
      const result = await editShippingAddress(body);
      const { data } = result || {};
      dataAddress = data
    } else {
      const result = await createNewShippingAddress(body);
      const { data } = result || {};
      dataAddress = data
    }
    const {
      id,
      first_name,
      last_name,
      address_details,
      city_id,
      city_label,
      province_id,
      province_label,
      subdistrict_id,
      subdistrict_label,
      postal_code,
      phone_number,
      address_name,
      country_id
    } = dataAddress || {};
    setShippingAddressState({
      shippingAddressId: id,
      firstName: first_name,
      lastName: last_name,
      addressDetails: address_details,
      city: {label: city_label, value: city_id},
      province: {label: province_label, value: province_id},
      subDistrict: {label: subdistrict_label, value: subdistrict_id},
      postalCode: postal_code,
      number: phone_number,
      country_id: country_id,
      isOpenDropdownCountry: false,
      addressName: address_name
    });
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  }

  const handleOnClickContinueToPaymentBtn = async () => {
    if (userDetail) {
      let checkedProducts = false;
      for (let l = 0; l < shoppingCarts.length; l++) {
        const { checked } = shoppingCarts[l];
        if (checked) {
          checkedProducts = true;
        }
      }
      if (checkedProducts) {
        const isValid = handleValidationClickContinueToPayment();
        if (isValid) {
          setIsOpenSlideTwo(true);
        } else {
          const beforeFunction = () => {
            return dispatch({
              type: "SET_NOTIFICATION",
              notification: {
                success: true,
                msg: "Please fill all required fields.",
                show: true,
              },
            });
          };
          const afterFunction = () => {
            return dispatch({
              type: "SET_NOTIFICATION",
              notification: {
                success: false,
                msg: "",
                show: false,
              },
            });
          };
          timer(beforeFunction, afterFunction, 3000);
        }
      } else {
        const beforeFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: true,
              msg: "Please select any item to purchase",
              show: true,
            },
          });
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "",
              show: false,
            },
          });
        };
        timer(beforeFunction, afterFunction, 3000);
      }
    } else {
      setIsSelectAllCarts(false);
      dispatch({
        type: "SET_AUTHENTICATION_MODAL",
        authenticationModal: {
          ...authenticationModal,
          login: true,
        },
      });
    }
  };

  const handleOnChangeCheckboxShippingOpt = async (
    availableShippingMethod,
    shippingOpt
  ) => {
    let finalChildrenShippingMethods = childrenShippingMethods;
    finalChildrenShippingMethods =
      finalChildrenShippingMethods &&
        Array.isArray(finalChildrenShippingMethods) &&
        finalChildrenShippingMethods.length > 0
        ? finalChildrenShippingMethods.map((childShipOpt) => {
          const { isSelected, courier_service_code } = childShipOpt || {};
          if (courier_service_code === shippingOpt.courier_service_code) {
            let value = isSelected;
            childShipOpt.isSelected = !value;
          } else {
            childShipOpt.isSelected = false;
          }
          return childShipOpt;
        })
        : [];
    setChildrenShippingMethods(finalChildrenShippingMethods);
    if (shippingOpt?.isSelected === true) {
      let totalItems = 0;
      let arrProducts =
        shoppingCarts &&
          Array.isArray(shoppingCarts) &&
          shoppingCarts.length > 0
          ? shoppingCarts.filter(cart => cart.checked).map((cart) => {
            const { product, qty, combination_id, checked, product_unit_price } = cart || {};
            if (checked) {
              totalItems += qty;
            }
            return { id: product.id, qty, combination_id, product_unit_price };
          })
          : [];

      setOrderSummaryParams(prev => ({
        ...prev,
        products: arrProducts,
        shipping_service: shippingOpt && shippingOpt.courier_service_name,
        shipping_address_id: shippingAddressState && shippingAddressState.shippingAddressId,
        shipping_method_identifier: shippingOpt && shippingOpt.courier_name,
      }))

      setTotalPrice(prev => ({
        ...prev,
        totalItems: totalItems,
      }));
    } else {
      setOrderSummaryParams(prev => ({
        ...prev,
        shipping_service: "",
        shipping_address_id: null,
        shipping_method_identifier: "",
      }))
    }
  };

  const [orderSummaryParams, setOrderSummaryParams] = useState({
    products: null,
    shipping_address_id: null,
    shipping_method_identifier: "",
    shipping_service: "",
    mpayment_channel_id: null,
  })

  const calculateOrderSummary = async (currency_id, body, promoCode) => {
    try {
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: true,
      });
      const bodyArray = Object.values(body)
      if (bodyArray?.every(item => item)) {
        const queries = formUrlQuery({
          currency_id
        })
        const result = await calculateCartOverview(queries, { ...body, promo_code: promoCode });
        const { data } = result || {};
        setTotalPrice(prev => ({
          ...prev,
          ...data,
        }));
      }
    } catch (error) {
      console.error(error)
    } finally {
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: false,
      });
    }
  }

  useEffect(() => {
    calculateOrderSummary(searchState?.currencyVal?.id, orderSummaryParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSummaryParams, searchState?.currencyVal?.id])

  const handleOnClickPayNowBtn = async () => {
    const body = {
      email: userDetail && userDetail.email,
      shipping_service: orderSummaryParams && orderSummaryParams.shipping_service,
      mpayment_channel_id:
        orderSummaryParams && orderSummaryParams.mpayment_channel_id,
      shipping_address_id:
        orderSummaryParams && orderSummaryParams.shipping_address_id,
      shipping_method_identifier:
        orderSummaryParams && orderSummaryParams.shipping_method_identifier,
      promo_code: promoCode,
    };
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await createTransaction(body);
    if (result && result.data) {
      const { data: { m_redirect_url } = {} } = result || {};
      window.location.replace(m_redirect_url);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleOnChangeDropdownUserShippingAddress = async (propValue) => {
    const { label, value } = propValue || {};
    if (label === "Create new address") {
      setIsEditableShippingAddressFields(true);
      setLocationDetails('')
      setLatLng({ lat: -6.1753917, lng: 106.8271533 })
      setMLatLng({ mLat: -6.1753917, mLng: 106.8271533 })
      setShippingAddressState({
        ...shippingAddressState,
        shippingAddressId: null,
        firstName: "",
        lastName: "",
        addressDetails: "",
        street_address: "",
        city: null,
        province: null,
        subDistrict: null,
        postalCode: "",
        number: "",
        icon: "",
        phone_code: "",
        country_id: "",
        isOpenDropdownCountry: false,
        addressName: ""
      });
    } else {
      setIsEditableShippingAddressFields(false);
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: true,
      });
      const {
        id,
        first_name,
        last_name,
        address_details,
        province_id,
        city_id,
        subdistrict_id,
        postal_code,
        phone_number,
        street_address,
        latitude_marker,
        longitude_marker,
        latitude_map,
        longitude_map,
        country,
      } = value;
      let countryIcon;
      if (country && country.country_code) {
        countryIcon = icon[`cif${country.country_code}`];
      }
      let provinceValue = null;
      let cityValue = null;
      let subDistrictValue = null;
      if (province_id) {
        const foundProvince = await getDetailProvince(province_id);
        if (foundProvince && foundProvince.data) {
          const { data: { province, province_id } = {} } = foundProvince;
          provinceValue = {
            value: province_id,
            label: province,
          };
        }
      }
      if (city_id) {
        const foundCity = await getDetailCity(city_id);
        if (foundCity && foundCity.data) {
          const { data: { city_name, city_id } = {} } = foundCity;
          cityValue = {
            value: city_id,
            label: city_name,
          };
        }
      }
      if (subdistrict_id) {
        const foundSubDistrict = await getDetailSubDistrict(subdistrict_id);
        if (foundSubDistrict && foundSubDistrict.data) {
          const { data: { subdistrict_name, subdistrict_id } = {} } =
            foundSubDistrict;
          subDistrictValue = {
            value: subdistrict_id,
            label: subdistrict_name,
          };
        }
      }
      if (street_address) {
        setLocationDetails(street_address)
      }
      if (latitude_map && longitude_map) {
        setLatLng({ lat: Number(latitude_map), lng: Number(longitude_map) })
      }
      if (latitude_marker && longitude_marker) {
        setMLatLng({ mLat: Number(latitude_marker), mLng: Number(longitude_marker) })
      }
      setShippingAddressState({
        shippingAddressId: id,
        firstName: first_name,
        lastName: last_name,
        addressDetails: address_details,
        street_address: street_address,
        latitude_marker: latitude_marker,
        longitude_marker: longitude_marker,
        latitude_map: latitude_map,
        longitude_map: longitude_map,
        city: cityValue,
        province: provinceValue,
        subDistrict: subDistrictValue,
        postalCode: postal_code,
        number: phone_number,
        icon: countryIcon,
        phone_code: country && country.phone_code,
        country_id: country && country.id,
        isOpenDropdownCountry: false,
        addressName: label
      });
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: false,
      });
    }
    setUserShippingAddressValue(propValue);
  };

  const handleClickStartShoppingNow = () => {
    if (searchState['category']) delete searchState['category']

    if (searchState['subCategories']) {
      delete searchState['subCategories']
      delete searchState['subCategory']
    }

    dispatch({
      type: "SET_SEARCH_STATE",
      searchState: {
        ...searchState,
      },
    });
    navigate("/items");
  }

  if (isOpenSuccessPaymentComponent) {
    return (
      <div className="shoppingCartSuccessPaymentMainContainer">
        <div className="successPaymentIconContainer">
          <img src={ValidIcon} alt="valid-icon" />
        </div>
        <div className="successPaymentBoxContainer">
          <div className="successPaymentContentContainer">
            <div className="thankyouForYourOrderStyle">
              Thank you for your order
            </div>
            <div className="thankyouForYourOrderDescStyle">
              The order confirmation has been sent to your email address
            </div>
            <div className="orderIdContainer">
              <div className="orderIdLabel">ORDER ID</div>
              <div className="orderIdBox">
                <div>#1271900SC</div>
              </div>
            </div>
            <div className="thankyouForYourOrderDivider">
              <div></div>
            </div>
            <div className="orderDescContainer">
              <div className="orderDescContentContainer">
                <div className="eachOrderDescContainer">
                  <div style={{ fontWeight: "500" }}>Date</div>
                  <div>03/02/2023</div>
                </div>
                <div className="eachOrderDescContainer">
                  <div style={{ fontWeight: "500" }}>Time</div>
                  <div>12:47</div>
                </div>
                <div className="eachOrderDescContainer">
                  <div style={{ fontWeight: "500" }}>Payment method</div>
                  <div>Credit card</div>
                </div>
                <div className="eachOrderDescContainer">
                  <div style={{ fontWeight: "500" }}>Amount</div>
                  <div>
                    {searchState &&
                      searchState.currencyVal &&
                      searchState.currencyVal.code}{" "}
                    608
                  </div>
                </div>
              </div>
            </div>
            <div className="pleaseScreenshotStyle">
              Please screenshot this page as your payment reference
            </div>
          </div>
        </div>
        <div className="buttonMainContainer">
          <div className="btnPrintContainer">
            <img src={PrintIcon} alt="print-icon" />
            <div>Print this page</div>
          </div>
          <div className="btnContinueShopping">
            <div style={{ color: "#FFF" }}>Continue shopping</div>
          </div>
        </div>
      </div>
    );
  } else {
    if (
      shoppingCarts &&
      Array.isArray(shoppingCarts) &&
      shoppingCarts.length > 0
    ) {
      return (
        <div className="shoppingCartMainContainer">
          <div className="shoppingCartContainer">
            {!isOpenSlideTwo ? (
              <div style={{ width: isMobileSize ? "100%" : "70%" }}>
                <div className="shoppingCartAndContactInformationAndShippingAddressContainer">
                  <div className="shoppingCartMenu">Shopping Cart</div>
                  {
                    isSelectAllCarts && (
                      <div
                        className="deleteAllCartItemContainer"
                        style={{ marginBottom: isMobileSize ? "1em" : "0" }}
                      >
                        <div
                          className="deleteAllCartIconAndLabelContainer"
                          onClick={() => handleDeleteAllItemInShoppingCart()}
                        >
                          <img src={DeleteProductIcon} alt="delete-icon" />
                          <div>REMOVE ALL</div>
                        </div>
                      </div>
                    )
                  }
                  {!isMobileSize ? (
                    <div className="headerShoppingCarts">
                      <div className="divInputCheckbox">
                        <input
                          type="checkbox"
                          id="checkbox"
                          name="selectAllCheckbox"
                          onChange={({ target: { checked } }) =>
                            handleSelectAllCartItems(checked)
                          }
                          checked={isSelectAllCarts}
                        />
                      </div>
                      <div className="headerShoppingCartsLabel">PRODUCT</div>
                      <div className="headerQtyShoppingCartsLabel">
                        QUANTITY
                      </div>
                      <div className="headerUnitShopLabel">UNIT PRICE</div>
                      <div className="headerUnitShopLabel">TOTAL PRICE</div>
                    </div>
                  ) : (
                    <div className="mobileDivInputCheckbox">
                      <input
                        type="checkbox"
                        id="checkbox"
                        name="selectAllCheckbox"
                        onChange={({ target: { checked } }) =>
                          handleSelectAllCartItems(checked)
                        }
                        checked={isSelectAllCarts}
                      />
                      <div className="ml-3">
                        SELECT ALL
                      </div>
                    </div>
                  )}
                  <div
                    className="dividerContent"
                    style={{ marginTop: "1vh" }}
                  ></div>
                  <div className="cartsContainer">
                    {shoppingCarts &&
                      Array.isArray(shoppingCarts) &&
                      shoppingCarts.length > 0 &&
                      shoppingCarts.map((cart, index) => {
                        const {
                          id,
                          checked,
                          product: { name, combinations, images } = {},
                          product,
                          qty,
                          product_unit_price,
                          cart_total_price,
                          onMouseOverMinus,
                          onMouseOverPlus,
                          combination
                        } = cart || {};
                        let variantSelectedList = []
                        if (combination &&
                          combination.combination_options &&
                          Array.isArray(combination.combination_options) &&
                          combination.combination_options.length > 0) {
                          combination.combination_options.sort((prev, next) => prev.variant_option.id > next.variant_option.id ? 1 : next.variant_option.id > prev.variant_option.id ? -1 : 0).forEach((item) => {
                            const { variant, variant_option } = item;
                            if (variant.name == 'Size') {
                              variantSelectedList.push(`${variant && variant.name}: ${variant_option && variant_option.name}`)
                            } else {
                              variantSelectedList.push(`${variant_option && variant_option.name}`)
                            }
                          })
                        }
                        let finalQty = combination ? combination.qty : product.qty
                        let img_url;
                        if (
                          images &&
                          Array.isArray(images) &&
                          images.length > 0
                        ) {
                          img_url = images[0] && images[0].image_url;
                        }
                        let arrCombinations = [];
                        if (
                          combinations &&
                          Array.isArray(combinations) &&
                          combinations.length > 0
                        ) {
                          combinations.forEach((combination) => {
                            const { combination_options } = combination || {};
                            if (
                              combination_options &&
                              Array.isArray(combination_options) &&
                              combination_options.length > 0
                            ) {
                              let objCombination;
                              combination_options.map((comb_opt) => {
                                const { variant, variant_option } = comb_opt;
                                if (
                                  variant &&
                                  variant_option &&
                                  variant_option.name &&
                                  variant.name &&
                                  (variant.name === "Color" ||
                                    variant.name === "Size")
                                ) {
                                  if (variant.name === "Color") {
                                    objCombination = {
                                      ...objCombination,
                                      color: variant_option.name,
                                    };
                                  }
                                  if (variant.name === "Size") {
                                    objCombination = {
                                      ...objCombination,
                                      size: variant_option.name,
                                    };
                                  }
                                }
                              });
                              arrCombinations.push(objCombination);
                            }
                          });
                        }
                        let unitPrice = `${searchState &&
                          searchState.currencyVal &&
                          searchState.currencyVal.code
                          } ${formatPrice(product_unit_price)}`;
                        let totalPrice = `${searchState &&
                          searchState.currencyVal &&
                          searchState.currencyVal.code
                          } ${formatPrice(cart_total_price)}`;

                        if (isMobileSize) {
                          return (
                            <div key={index} className="eachCartContainer">
                              <div className="divInputCheckbox">
                                <input
                                  type="checkbox"
                                  id="checkbox"
                                  name="eachCartName"
                                  onChange={({ target: { checked } }) =>
                                    handleOnClickEachCheckbox(checked, index)
                                  }
                                  checked={checked}
                                />
                              </div>
                              <div
                                className="cartImageContainer"
                                onClick={() => navigate(`/items/${product.id}`)}
                              >
                                <img src={img_url} alt="product-img" />
                              </div>
                              <div style={{ width: "100%" }}>
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "30vw",
                                    }}
                                    onClick={() =>
                                      navigate(`/items/${product.id}`)
                                    }
                                  >
                                    <div className="cartProductName">
                                      {name}
                                    </div>
                                    {
                                      variantSelectedList && Array.isArray(variantSelectedList) && variantSelectedList.length > 0 && (
                                        <div className="cartVariantProduct">
                                          {variantSelectedList.join(', ')}
                                        </div>
                                      )
                                    }
                                    <div className="cartVariantContainer">
                                      {arrCombinations &&
                                        Array.isArray(arrCombinations) &&
                                        arrCombinations.length > 0 &&
                                        arrCombinations.map(
                                          (combination, idx) => {
                                            const { color, size } =
                                              combination || {};
                                            let combinationValue = "";
                                            if (color && size) {
                                              combinationValue = `${color}, ${size}`;
                                            }
                                            return (
                                              <div
                                                key={idx}
                                                className="eachCartVariantContainer"
                                              >
                                                {combinationValue}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                    <div
                                      className="cartUnitPriceContainer"
                                      style={{ marginTop: "1em" }}
                                    >
                                      <div style={{ fontWeight: "600" }}>
                                        {totalPrice}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cartTotalPriceContainer">
                                    <img
                                      src={DeleteProductIcon}
                                      alt="delete-icon"
                                      onClick={() => handleOnDeleteEachItem(id)}
                                    />
                                    <div className="cartQtyContainer">
                                      <div className="cartDescriptionQtyOptionsContainer">
                                        <div
                                          className={
                                            isMobileSize
                                              ? "itemDetailDescriptionQtyOptionsMobile"
                                              : "itemDetailDescriptionQtyOptions"
                                          }
                                        >
                                          <div
                                            className={
                                              isMobileSize
                                                ? "itemDetailDescriptionQtyBtnMobile"
                                                : "itemDetailDescriptionQtyBtn"
                                            }
                                            onMouseOver={() =>
                                              (
                                                finalQty &&
                                                finalQty !== 0
                                              )
                                                ? handleOnMouseHover(
                                                  "minus",
                                                  true,
                                                  id
                                                )
                                                : null
                                            }
                                            onMouseLeave={() =>
                                              handleOnMouseHover(
                                                "minus",
                                                false,
                                                id
                                              )
                                            }
                                            onClick={() =>
                                              handleClickPlusAndMinusBtnOnCart(
                                                "minus",
                                                id
                                              )
                                            }
                                            style={
                                              (
                                                finalQty &&
                                                finalQty !== 0
                                              )
                                                ? {
                                                  color: onMouseOverMinus
                                                    ? "#000000"
                                                    : "#d9d9d9",
                                                  cursor: "pointer",
                                                }
                                                : {
                                                  color: onMouseOverMinus
                                                    ? "#000000"
                                                    : "#d9d9d9",
                                                  pointerEvents: "none",
                                                }
                                            }
                                          >
                                            -
                                          </div>
                                          <div
                                            className={
                                              isMobileSize
                                                ? "itemDetailDescriptionQtyValueMobile"
                                                : "itemDetailDescriptionQtyValue"
                                            }
                                          >
                                            {qty}
                                          </div>
                                          <div
                                            className={
                                              isMobileSize
                                                ? "itemDetailDescriptionQtyBtnMobile"
                                                : "itemDetailDescriptionQtyBtn"
                                            }
                                            onMouseOver={() =>
                                              handleOnMouseHover(
                                                "plus",
                                                true,
                                                id
                                              )
                                            }
                                            onMouseLeave={() =>
                                              handleOnMouseHover(
                                                "plus",
                                                false,
                                                id
                                              )
                                            }
                                            onClick={() =>
                                              handleClickPlusAndMinusBtnOnCart(
                                                "plus",
                                                id
                                              )
                                            }
                                            style={
                                              (
                                                finalQty &&
                                                (qty || qty === 0) &&
                                                qty + 1 <= finalQty
                                              )
                                                ? {
                                                  color: onMouseOverPlus
                                                    ? "#000000"
                                                    : "#d9d9d9",
                                                  cursor: "pointer",
                                                }
                                                : {
                                                  color: onMouseOverPlus
                                                    ? "#000000"
                                                    : "#d9d9d9",
                                                  pointerEvents: "none",
                                                }
                                            }
                                          >
                                            +
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div key={index} className="eachCartContainer">
                              <div className="divInputCheckbox">
                                <input
                                  type="checkbox"
                                  id="checkbox"
                                  name="eachCartName"
                                  onChange={({ target: { checked } }) =>
                                    handleOnClickEachCheckbox(checked, index)
                                  }
                                  checked={checked}
                                />
                              </div>
                              <div
                                className="cartImageContainer"
                                onClick={() => navigate(`/items/${product.id}`)}
                              >
                                <img src={img_url} alt="product-img" />
                                <div className="cartProductName">{name}</div>
                                {
                                  variantSelectedList && Array.isArray(variantSelectedList) && variantSelectedList.length > 0 && (
                                    <div className="cartVariantProduct">
                                      {variantSelectedList.join(', ')}
                                    </div>
                                  )
                                }
                              </div>
                              <div className="cartQtyContainer">
                                <div className="cartDescriptionQtyOptionsContainer">
                                  <div
                                    className={
                                      isMobileSize
                                        ? "itemDetailDescriptionQtyOptionsMobile"
                                        : "itemDetailDescriptionQtyOptions"
                                    }
                                  >
                                    <div
                                      className={
                                        isMobileSize
                                          ? "itemDetailDescriptionQtyBtnMobile"
                                          : "itemDetailDescriptionQtyBtn"
                                      }
                                      onMouseOver={() =>
                                        (
                                          finalQty &&
                                          finalQty !== 0
                                        )
                                          ? handleOnMouseHover(
                                            "minus",
                                            true,
                                            id
                                          )
                                          : null
                                      }
                                      onMouseLeave={() =>
                                        handleOnMouseHover("minus", false, id)
                                      }
                                      onClick={() =>
                                        handleClickPlusAndMinusBtnOnCart(
                                          "minus",
                                          id
                                        )
                                      }
                                      style={
                                        (
                                          finalQty &&
                                          finalQty !== 0
                                        )
                                          ? {
                                            color: onMouseOverMinus
                                              ? "#000000"
                                              : "#d9d9d9",
                                            cursor: "pointer",
                                          }
                                          : {
                                            color: onMouseOverMinus
                                              ? "#000000"
                                              : "#d9d9d9",
                                            pointerEvents: "none",
                                          }
                                      }
                                    >
                                      -
                                    </div>
                                    <div
                                      className={
                                        isMobileSize
                                          ? "itemDetailDescriptionQtyValueMobile"
                                          : "itemDetailDescriptionQtyValue"
                                      }
                                    >
                                      {qty}
                                    </div>
                                    <div
                                      className={
                                        isMobileSize
                                          ? "itemDetailDescriptionQtyBtnMobile"
                                          : "itemDetailDescriptionQtyBtn"
                                      }
                                      onMouseOver={() =>
                                        handleOnMouseHover("plus", true, id)
                                      }
                                      onMouseLeave={() =>
                                        handleOnMouseHover("plus", false, id)
                                      }
                                      onClick={() =>
                                        handleClickPlusAndMinusBtnOnCart(
                                          "plus",
                                          id
                                        )
                                      }
                                      style={
                                        (
                                          finalQty &&
                                          (qty || qty === 0) &&
                                          qty + 1 <= finalQty
                                        )
                                          ? {
                                            color: onMouseOverPlus
                                              ? "#000000"
                                              : "#d9d9d9",
                                            cursor: "pointer",
                                          }
                                          : {
                                            color: onMouseOverPlus
                                              ? "#000000"
                                              : "#d9d9d9",
                                            pointerEvents: "none",
                                          }
                                      }
                                    >
                                      +
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="cartVariantContainer">
                                  {arrCombinations &&
                                    Array.isArray(arrCombinations) &&
                                    arrCombinations.length > 0 &&
                                    arrCombinations.map((combination, idx) => {
                                      const { color, size } = combination || {};
                                      let combinationValue = "";
                                      if (color && size) {
                                        combinationValue = `${color}, ${size}`;
                                      }
                                      return (
                                        <div
                                          key={idx}
                                          className="eachCartVariantContainer"
                                        >
                                          {combinationValue}
                                        </div>
                                      );
                                    })}
                                </div> */}
                              </div>
                              <div className="cartUnitPriceContainer">
                                <div>{unitPrice}</div>
                              </div>
                              <div className="cartTotalPriceContainer">
                                <div>{totalPrice}</div>
                                <img
                                  src={DeleteProductIcon}
                                  alt="delete-icon"
                                  onClick={() => handleOnDeleteEachItem(id)}
                                />
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                  {isMobileSize ? (
                    <div
                      className="dividerContent"
                      style={{ marginTop: "0.1em" }}
                    ></div>
                  ) : null}
                </div>
                {userDetail && (
                  <>
                    {/* <div className="shoppingCartAndContactInformationAndShippingAddressContainer">
                      <div className="shoppingCartMenu">
                        Contact information
                      </div>
                      <div className="inputContainer">
                        <div style={{ width: isMobileSize ? "100%" : "47.5%" }}>
                          <div className="inputLabel">First name</div>
                          <InputText
                            placeholder={"Fill in your first name"}
                            width={"100%"}
                            height={isMobileSize ? "37.5px" : "5vh"}
                            border={"1px solid #000000"}
                            borderRadius={"5px"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            name="contactInfoFirstName"
                            value={contactInfoState.firstName}
                            setter={(value) =>
                              setContactInfoState({
                                ...contactInfoState,
                                firstName: value,
                              })
                            }
                          />
                          {invalidCISfirstName &&
                            !contactInfoState.firstName ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            width: isMobileSize ? "100%" : "47.5%",
                            paddingTop: isMobileSize ? "1em" : "0",
                          }}
                        >
                          <div className="inputLabel">Last name</div>
                          <InputText
                            placeholder={"Fill in your last name"}
                            width={"100%"}
                            height={isMobileSize ? "37.5px" : "5vh"}
                            border={"1px solid #000000"}
                            borderRadius={"5px"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            name="contactInfoLastName"
                            value={contactInfoState.lastName}
                            setter={(value) =>
                              setContactInfoState({
                                ...contactInfoState,
                                lastName: value,
                              })
                            }
                          />
                          {invalidCISlastName && !contactInfoState.lastName ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="inputPhoneContainer">
                        <div className="inputLabel">Phone</div>
                        <div
                          className="inputPhoneBox"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="inputPhoneCode"
                            style={{ height: isMobileSize ? "" : "5.2vh" }}
                            onClick={() => {
                              let currentValue =
                                contactInfoState &&
                                contactInfoState.isOpenDropdownCountry;
                              setContactInfoState({
                                ...contactInfoState,
                                isOpenDropdownCountry: !currentValue,
                              });
                            }}
                          >
                            {contactInfoState && contactInfoState.icon ? (
                              <div
                                style={{
                                  width: isMobileSize ? "6vw" : "1.7vw",
                                }}
                              >
                                <CIcon icon={contactInfoState.icon} size="sm" />
                              </div>
                            ) : (
                              "Country"
                            )}
                          </div>
                          <InputText
                            placeholder={"Fill in phone number"}
                            width={"100%"}
                            height={isMobileSize ? "37.5px" : "5vh"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            name={"phoneNumberContactInformation"}
                            border={"1px solid #000000"}
                            borderRadius={"5px"}
                            functionSetter={handleOnChangePhoneNumber}
                            phoneNumberState={contactInfoState}
                            setterPhoneNumber={setContactInfoState}
                            value={
                              contactInfoState &&
                                contactInfoState.number &&
                                contactInfoState.phone_code
                                ? `(${contactInfoState.phone_code}) ${contactInfoState.number}`
                                : contactInfoState &&
                                  contactInfoState.phone_code &&
                                  !contactInfoState.number
                                  ? `(${contactInfoState.phone_code}) `
                                  : contactInfoState &&
                                    contactInfoState.number &&
                                    !contactInfoState.phone_code
                                    ? contactInfoState.number
                                    : ""
                            }
                          />
                        </div>
                        {(invalidCIScountryId &&
                          !contactInfoState.country_id) ||
                          (invalidCISphoneNumber && !contactInfoState.number) ? (
                          <div className="errorMessage">
                            This field is required
                          </div>
                        ) : null}
                        {contactInfoState &&
                          contactInfoState.isOpenDropdownCountry ? (
                          <InputDropdownAsync
                            options={countryOptions}
                            styles={{
                              marginTop: "1.25vh",
                              border: "1px solid #000000",
                              borderRadius: "5px",
                              height: isMobileSize ? "37.5px" : "5.25vh",
                              fontSize: isMobileSize ? "14px" : "1vw",
                              cursor: "pointer",
                            }}
                            phoneNumberState={contactInfoState}
                            setterPhoneNumber={setContactInfoState}
                            functionSetter={handleChangePhoneNumberDropdown}
                          />
                        ) : null}
                        <div className="useSameInfoContainer">
                          <div
                            className="divInputCheckbox"
                            style={{ marginLeft: "-0.5vw" }}
                          >
                            <input
                              type="checkbox"
                              style={{ marginLeft: "0", cursor: "pointer" }}
                              onChange={({ target: { checked } }) =>
                                handleOnChangeUseInformation(checked)
                              }
                            />
                          </div>
                          <div className="useSameInfoLabel">
                            Use the same information as my account
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div
                      className="shoppingCartAndContactInformationAndShippingAddressContainer"
                      style={{ marginTop: "5vh" }}
                    >
                      {/* handson disini */}
                      <div className="shoppingCartMenu">Shipping Address</div>
                      {userShippingAddressOptions &&
                        Array.isArray(userShippingAddressOptions) &&
                        userShippingAddressOptions.length > 0 ? (
                        <div
                          className="inputContainer"
                          style={{ marginTop: isMobileSize ? "0.5em" : "2vh" }}
                        >
                          <div
                            style={{ width: isMobileSize ? "100%" : "100%" }}
                          >
                            <div className="inputLabel">Choose address</div>
                            <InputDropdown
                              options={userShippingAddressOptions}
                              placeholder={"Choose address..."}
                              styles={{
                                border: "1px solid #000000",
                                borderRadius: "5px",
                                height: isMobileSize ? "37.5px" : "5.25vh",
                                cursor: "pointer",
                                width: "100%",
                                fontSize: isMobileSize ? "14px" : "1vw",
                                menuWidth: isMobileSize ? "75vw" : "100%",
                              }}
                              functionSetter={(value) =>
                                handleOnChangeDropdownUserShippingAddress(value)
                              }
                              value={userShippingAddressValue}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="inputContainer">
                        <div style={{ width: isMobileSize ? "100%" : "47.5%" }}>
                          <div className="inputLabel">First name</div>
                          <InputText
                            placeholder={"Fill in your first name"}
                            width={"100%"}
                            height={isMobileSize ? "37.5px" : "5vh"}
                            name={"firstNameShippingAddress"}
                            border={"1px solid #000000"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            borderRadius={"5px"}
                            // disabled={!isEditableShippingAddressFields}
                            value={shippingAddressState.firstName}
                            setter={(value) =>
                              setShippingAddressState({
                                ...shippingAddressState,
                                firstName: value,
                              })
                            }
                          />
                          {invalidSASfirstName &&
                            !shippingAddressState.firstName ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            width: isMobileSize ? "100%" : "47.5%",
                            paddingTop: isMobileSize ? "1em" : "0",
                          }}
                        >
                          <div className="inputLabel">Last name</div>
                          <InputText
                            placeholder={"Fill in your last name"}
                            width={"100%"}
                            height={isMobileSize ? "37.5px" : "5vh"}
                            name={"lastNameShippingAddress"}
                            border={"1px solid #000000"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            borderRadius={"5px"}
                            value={shippingAddressState.lastName}
                            setter={(value) =>
                              setShippingAddressState({
                                ...shippingAddressState,
                                lastName: value,
                              })
                            }
                          />
                          {invalidSASlastName &&
                            !shippingAddressState.lastName ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="inputContainer">
                        <div style={{ width: "100%" }}>
                          <div className="inputLabel">Address location</div>
                          <GoogleMaps
                            dispatch={dispatch}
                            latLng={latLng}
                            mLatLng={mLatLng}
                            locationDetails={locationDetails}
                            setLatLng={setLatLng}
                            setMLatLng={setMLatLng}
                            setLocationDetails={setLocationDetails}
                            shippingAddressCheckout={true}
                          />
                        </div>
                      </div>
                      <div className="inputContainer">
                        <div style={{ width: isMobileSize ? "100%" : "47.5%" }}>
                          <div className="inputLabel">Street address</div>
                          <InputTextArea
                            width={"100%"}
                            border={"1px solid #000000"}
                            borderRadius={"5px"}
                            isMobileSize={isMobileSize}
                            height={isMobileSize ? "61px" : "9vh"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            // placeholder={"Fill in street address"}
                            name={"shippingAddressStreetAddress"}
                            value={locationDetails}
                            disabled={true}
                            // setter={(value) =>
                            //   setShippingAddressState({
                            //     ...shippingAddressState,
                            //     addressDetails: value,
                            //   })
                            // }
                          />
                          {invalidSASstreetAddress &&
                            !locationDetails ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                        <div style={{ width: isMobileSize ? "100%" : "47.5%" }}>
                          <div className="inputLabel">Address details</div>
                          <InputTextArea
                            width={"100%"}
                            border={"1px solid #000000"}
                            borderRadius={"5px"}
                            isMobileSize={isMobileSize}
                            height={isMobileSize ? "61px" : "9vh"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            placeholder={"Fill in address details"}
                            name={"shippingAddressAddressDetails"}
                            value={shippingAddressState.addressDetails}
                            // disabled={!isEditableShippingAddressFields}
                            setter={(value) =>
                              setShippingAddressState({
                                ...shippingAddressState,
                                addressDetails: value,
                              })
                            }
                          />
                          {invalidSASaddressDetails &&
                            !shippingAddressState.addressDetails ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        style={{
                          width: isMobileSize ? "100%" : "47.5%",
                          paddingTop: isMobileSize ? "1em" : "0",
                        }}
                      >
                        <div className="inputLabel">Save address as</div>
                        <InputText
                          placeholder={"Fill your address name"}
                          width={"100%"}
                          height={isMobileSize ? "37.5px" : "5vh"}
                          fontSize={isMobileSize ? "14px" : "1vw"}
                          name={"addressNameShippingAddress"}
                          border={"1px solid #000000"}
                          borderRadius={"5px"}
                          value={shippingAddressState.addressName}
                          setter={(value) =>
                            setShippingAddressState({
                              ...shippingAddressState,
                              addressName: value,
                            })
                          }
                        />
                        {invalidSASaddressName && !shippingAddressState.addressName ? (
                          <div className="errorMessage">This field is required</div>
                        ) : null}
                      </div>
                      <div
                        className="inputContainer"
                        style={{ marginTop: isMobileSize ? "0.5em" : "2vh" }}
                      >
                        <div style={{ width: isMobileSize ? "100%" : "47.5%" }}>
                          <div className="inputLabel">Province</div>
                          <InputDropdown
                            options={provinceOptions}
                            placeholder={"Select Province..."}
                            styles={{
                              border: "1px solid #000000",
                              borderRadius: "5px",
                              height: isMobileSize ? "37.5px" : "5.25vh",
                              cursor: "pointer",
                              width: "100%",
                              fontSize: isMobileSize ? "14px" : "1vw",
                            }}
                            // disabled={!isEditableShippingAddressFields}
                            functionSetter={(value) =>
                              handleOnChangeDropdownProvinceCityAndSubDistrict(
                                value,
                                "province"
                              )
                            }
                            value={shippingAddressState.province}
                          />
                          {invalidSASProvince &&
                            !shippingAddressState.province ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            width: isMobileSize ? "100%" : "47.5%",
                            paddingTop: isMobileSize ? "1em" : "0",
                          }}
                        >
                          <div className="inputLabel">Town/city</div>
                          <InputDropdown
                            options={cityOptions}
                            placeholder={"Select City..."}
                            styles={{
                              border: "1px solid #000000",
                              borderRadius: "5px",
                              height: isMobileSize ? "37.5px" : "5.25vh",
                              cursor: "pointer",
                              width: "100%",
                              fontSize: isMobileSize ? "14px" : "1vw",
                            }}
                            // disabled={!isEditableShippingAddressFields}
                            functionSetter={(value) =>
                              handleOnChangeDropdownProvinceCityAndSubDistrict(
                                value,
                                "city"
                              )
                            }
                            value={shippingAddressState.city}
                          />
                          {invalidSASCity && !shippingAddressState.city ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="inputContainer"
                        style={{
                          marginTop: isMobileSize ? "0" : "2vh",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            width: isMobileSize ? "100%" : "47.5%",
                            paddingTop: isMobileSize ? "1em" : "0",
                          }}
                        >
                          <div className="inputLabel">Sub district</div>
                          <InputDropdown
                            options={subDistrictOptions}
                            placeholder={"Select Sub District..."}
                            styles={{
                              border: "1px solid #000000",
                              borderRadius: "5px",
                              height: isMobileSize ? "37.5px" : "5.25vh",
                              cursor: "pointer",
                              width: "100%",
                              fontSize: isMobileSize ? "14px" : "1vw",
                            }}
                            functionSetter={(value) =>
                              handleOnChangeDropdownProvinceCityAndSubDistrict(
                                value,
                                "sub-district"
                              )
                            }
                            value={shippingAddressState.subDistrict}
                          />
                          {invalidSASSubDistrict &&
                            !shippingAddressState.subDistrict ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            width: isMobileSize ? "100%" : "47.5%",
                            paddingTop: isMobileSize ? "1em" : "0",
                          }}
                        >
                          <div className="inputLabel">Postal code</div>
                          <InputText
                            placeholder={"Fill in postal code"}
                            width={"100%"}
                            height={isMobileSize ? "35px" : "5vh"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            name={"shippingAddressPostalCode"}
                            border={"1px solid #000000"}
                            borderRadius={"5px"}
                            value={shippingAddressState.postalCode}
                            setter={(value) =>
                              setShippingAddressState({
                                ...shippingAddressState,
                                postalCode: value,
                              })
                            }
                          />
                          {invalidSASpostalCode &&
                            !shippingAddressState.postalCode ? (
                            <div className="errorMessage">
                              This field is required
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="inputPhoneContainer">
                        <div className="inputLabel">Phone</div>
                        <PhoneNumberInput
                          selectedCountry={shippingAddressState}
                          onChangeCountry={(value) => handleChangePhoneNumberDropdown(value, shippingAddressState, setShippingAddressState)}
                          phoneNumberValue={shippingAddressState?.number}
                          onChangePhoneNumber={(value) => setShippingAddressState(prev => ({ ...prev, number: value }))}
                          countryOptions={countryOptions}
                          isMobileSize={isMobileSize}
                        />
                        {(invalidSAScountryId &&
                          !shippingAddressState.country_id) ||
                          (invalidSASphoneNumber &&
                            !shippingAddressState.number) ? (
                          <div className="errorMessage">
                            This field is required
                          </div>
                        ) : null}
                        <div
                          className="inputContainer"
                          style={{ marginTop: "0" }}
                        >
                          <div style={{ flex: 1 }}>
                            {shippingAddressState &&
                              shippingAddressState.isOpenDropdownCountry ? (
                              <InputDropdownAsync
                                options={countryOptions}
                                styles={{
                                  marginTop: "1em",
                                  border: "1px solid #000000",
                                  borderRadius: "5px",
                                  height: "45px",
                                  cursor: "pointer",
                                }}
                                phoneNumberState={shippingAddressState}
                                setterPhoneNumber={setShippingAddressState}
                                functionSetter={handleChangePhoneNumberDropdown}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="inputPhoneContainer">
                        <div className="inputLabel">Additional Notes <span className="text-base text-slate-400">(Optional)</span></div>
                          <InputText
                            placeholder={"Notes about your order, e.g. special notes for delivery"}
                            width={"100%"}
                            height={isMobileSize ? "37.5px" : "5vh"}
                            name={"firstNameShippingAddress"}
                            border={"1px solid #000000"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            borderRadius={"5px"}
                            // disabled={!isEditableShippingAddressFields}
                            value={shippingAddressState.additionalNotes}
                            setter={(value) =>
                              setShippingAddressState({
                                ...shippingAddressState,
                                additionalNotes: value,
                              })
                            }
                          />
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div>
                <div className="shoppingCartAndContactInformationAndShippingAddressContainer">
                  <button className="mb-4 flex space-x-3 items-center" onClick={() => setIsOpenSlideTwo(false)}>
                    <img src={BackToPreviousPageIcon} alt="back-to-previous-page" className="h-3 w-3 sm:h-4 sm:w-4 object-contain" />
                    <span className="text-sm sm:text-base">Back to shopping cart</span>
                  </button>
                  <div className="shoppingCartMenu">Complete your Purchase</div>
                  <div className="completeYourPurchaseLabel">
                    Complete your purchase by providing shipping and payment details below
                  </div>

                  <div className="eachSubBabContainer">
                    <div className="eachSubBabTitle">
                      Available Shipping Method
                    </div>
                    {availableShippingMethods &&
                      Array.isArray(availableShippingMethods) &&
                      availableShippingMethods.length > 0 &&
                      availableShippingMethods.map(
                        (availableShippingMethod, index) => {
                          const { checked, identifier, name } = availableShippingMethod || {};
                          return (
                            <div
                              className="eachBoxOptionContainer"
                              key={index}
                              style={{
                                border: checked ? "1px solid #000" : "",
                              }}
                            >
                              <div className="eachBoxContainerShippingMethod">
                                <div
                                  className="eachOptionName w-full"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    cursor: "pointer"
                                  }}
                                  onClick={() =>
                                    handleOnClickAvailableShippingCheckbox(
                                      checked,
                                      index
                                    )
                                  }
                                >
                                  <div className="flex items-center">
                                    <div
                                      className={
                                        !checked && isMobileSize
                                          ? "eachBoxContainerShippingMethodEllipsis"
                                          : ""
                                      }
                                    >
                                      {name}
                                    </div>
                                    {/* <div
                                      style={{
                                        marginLeft: isMobileSize
                                          ? "0.3em"
                                          : "0.5vw",
                                        fontWeight: "400",
                                        fontSize: isMobileSize
                                          ? "12px"
                                          : "1vw",
                                      }}
                                    >{`(${identifier})`}
                                    </div> */}
                                  </div>
                                  <div
                                    style={
                                      checked
                                        ? {
                                          marginTop: "0.1em",
                                        }
                                        : {
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "0.1em",
                                        }
                                    }
                                  >
                                    <img
                                      src={
                                        checked ? ArrowDownIcon : ArrowUpIcon
                                      }
                                      width={17}
                                      height={10}
                                      alt="checkbox-icon"
                                      style={{ cursor: "pointer" }}

                                    />
                                  </div>
                                </div>
                                {
                                  checked ?
                                      childrenShippingMethods &&
                                      Array.isArray(childrenShippingMethods) &&
                                      childrenShippingMethods.length > 0 ? (
                                        <div className="eachOptionEstimateTime">
                                          {childrenShippingMethods.map(
                                            (shippingOpt, idx) => {
                                              const {
                                                cost,
                                                isSelected,
                                                price,
                                                courier_service_name,
                                                shipment_duration_range,
                                                shipment_duration_unit,
                                              } = shippingOpt || {};
                                              return (
                                                <div
                                                  key={idx}
                                                  className="eachShippingOptContainer"
                                                >
                                                  <div className="flex items-center gap-3">
                                                    <img
                                                      src={
                                                        isSelected
                                                          ? CheckedSizeConvertionIcon
                                                          : UncheckedSizeConvertionIcon
                                                      }
                                                      width={16}
                                                      height={16}
                                                      alt="checkbox-icon"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        handleOnChangeCheckboxShippingOpt(
                                                          availableShippingMethod,
                                                          shippingOpt
                                                        )
                                                      }
                                                    />
                                                    <div>
                                                      <div
                                                        style={{
                                                          fontSize: isMobileSize
                                                            ? "13px"
                                                            : "1vw",
                                                        }}
                                                      >
                                                        {courier_service_name}
                                                      </div>
                                                      <div
                                                        style={{
                                                          fontSize: isMobileSize
                                                            ? "11px"
                                                            : "0.75vw",
                                                        }}
                                                      >
                                                        {shipment_duration_range} {shipment_duration_unit}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="checkboxAndLabelContainer">
                                                    <div className="eachOptionEstimateTime">
                                                      {price
                                                        ? `${searchState &&
                                                        searchState.currencyVal &&
                                                        searchState
                                                          .currencyVal.code
                                                        } ${formatPrice(
                                                          price
                                                        )}`
                                                        : ""}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      ) : (
                                        <div className="eachOptionEstimateTime">
                                          <span>No available</span>
                                        </div>
                                      )
                                    : null
                                }
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>

                  <div className="eachSubBabContainer">
                    <div className="eachSubBabTitle">Payment method</div>
                    {paymentChannels &&
                      Array.isArray(paymentChannels) &&
                      paymentChannels.length > 0 &&
                      paymentChannels.map((paymentChannel, index) => {
                        const { name, checked } = paymentChannel || {};
                        return (
                          <div
                            className="eachBoxOptionContainer"
                            key={index}
                            style={{
                              border: checked ? "1px solid #000" : "",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleOnClickPaymentDetailCheckbox(checked, index)
                            }
                          >
                            <div className="eachBoxContainer">
                              <div className="eachOptionName">{name}</div>
                              <div className="checkboxAndLabelContainer">
                                <img
                                  src={
                                    checked
                                      ? CheckedSizeConvertionIcon
                                      : UncheckedSizeConvertionIcon
                                  }
                                  width={16}
                                  height={16}
                                  alt="checkbox-icon"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {/* {isOpenPaymentDetailForm ? (
                    <div
                      className="shoppingCartAndContactInformationAndShippingAddressContainer"
                      style={{ marginTop: "4vh", marginBottom: "8vh" }}
                    >
                      <div className="eachSubBabTitle">Payment details</div>
                      <div className="inputContainer">
                        <div style={{ width: isMobileSize ? "100%" : "47.5%" }}>
                          <div className="inputLabel">Card details</div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={
                                cardDetailsPaymentDetail
                                  ? CardNumberIcon
                                  : CardNumberEmptyIcon
                              }
                              alt="card-number-icon"
                              style={{
                                marginRight: "-1vw",
                                paddingLeft: "0.9vw",
                                width: isMobileSize ? "1.25vw" : "2vw",
                                height: "auto",
                                zIndex: "2",
                              }}
                            />
                            <InputText
                              placeholder={"Fill in your card number"}
                              width={"100%"}
                              height={isMobileSize ? "37.5px" : "5vh"}
                              fontSize={isMobileSize ? "14px" : "1vw"}
                              name={"cardDetailPaymentDetail"}
                              border={
                                invalidCardDetails
                                  ? "1px solid #B6783F"
                                  : "1px solid #000000"
                              }
                              backgroundColor={
                                invalidCardDetails ? "#FFF4EA" : ""
                              }
                              borderRadius={"5px"}
                              marginLeft={"-1.15vw"}
                              paddingLeft={"3.25vw"}
                              setter={setCardDetailsPaymentDetail}
                            />
                            {invalidCardDetails ? (
                              <div
                                className="validIconContainer"
                                style={{ marginLeft: "-2.61vw" }}
                              >
                                <img src={ValidIcon} alt="valid-icon" />
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          style={{
                            width: isMobileSize ? "100%" : "47.5%",
                            paddingTop: isMobileSize ? "1em" : "0",
                          }}
                        >
                          <div className="inputLabel">CVC number</div>
                          <div style={{ display: "flex" }}>
                            <InputText
                              placeholder={"Enter your CVC number"}
                              width={"100%"}
                              height={isMobileSize ? "37.5px" : "5vh"}
                              fontSize={isMobileSize ? "14px" : "1vw"}
                              name={"CVCPaymentDetail"}
                              border={
                                invalidCVCPaymmentDetail
                                  ? "1px solid #B6783F"
                                  : "1px solid #000000"
                              }
                              backgroundColor={
                                invalidCVCPaymmentDetail ? "#FFF4EA" : ""
                              }
                              borderRadius={"5px"}
                            />
                            {invalidCVCPaymmentDetail ? (
                              <div
                                className="validIconContainer"
                                style={{ marginLeft: "-2.61vw" }}
                              >
                                <img src={ValidIcon} alt="valid-icon" />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="inputContainer">
                        <div style={{ width: isMobileSize ? "100%" : "47.5%" }}>
                          <div className="inputLabel">Card holder number</div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={
                                cardHolderNameDetailsPaymentDetail
                                  ? CardHolderNumberIcon
                                  : CardHolderNumberEmptyIcon
                              }
                              alt="card-holder-number-icon"
                              style={{
                                marginRight: "-1vw",
                                paddingLeft: "1vw",
                                width: isMobileSize ? "1.15vw" : "2vw",
                                height: "auto",
                                zIndex: "2",
                              }}
                            />
                            <InputText
                              placeholder={"Fill in card holder number"}
                              width={"100%"}
                              height={isMobileSize ? "37.5px" : "5vh"}
                              fontSize={isMobileSize ? "14px" : "1vw"}
                              name={"cardHolderNumberPaymentDetails"}
                              border={"1px solid #000000"}
                              borderRadius={"5px"}
                              marginLeft={"-1.15vw"}
                              paddingLeft={"3.25vw"}
                              setter={setCardHolderNameDetailsPaymentDetail}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: isMobileSize ? "100%" : "47.5%",
                            paddingTop: isMobileSize ? "1em" : "0",
                          }}
                        >
                          <div className="inputLabel">Expiry date</div>
                          <div style={{ display: "flex" }}>
                            <InputText
                              placeholder={"MM/YY"}
                              width={"100%"}
                              height={isMobileSize ? "37.5px" : "5vh"}
                              fontSize={isMobileSize ? "14px" : "1vw"}
                              name={"expiryDatePaymentDetail"}
                              border={
                                invalidExpireDate
                                  ? "1px solid #B6783F"
                                  : "1px solid #000000"
                              }
                              backgroundColor={
                                invalidExpireDate ? "#FFF4EA" : ""
                              }
                              borderRadius={"5px"}
                            />
                            {invalidExpireDate ? (
                              <div
                                className="validIconContainer"
                                style={{ marginLeft: "-2.61vw" }}
                              >
                                <img src={ValidIcon} alt="valid-icon" />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="inputContainer">
                        <div style={{ width: "100%" }}>
                          <div className="inputLabel">Billing address</div>
                          <InputTextArea
                            width={isMobileSize ? "95%" : "100%"}
                            isMobileSize={isMobileSize}
                            border={"1px solid #000000"}
                            borderRadius={"5px"}
                            height={"9vh"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            placeholder={"Fill in billing address"}
                            name={"billingAddressPaymentDetail"}
                          />
                        </div>
                      </div>
                      <div className="inputContainer">
                        <div style={{ width: isMobileSize ? "100%" : "47.5%" }}>
                          <div className="inputLabel">Postal code</div>
                          <InputText
                            placeholder={"Fill in postal code"}
                            width={"100%"}
                            height={isMobileSize ? "37.5px" : "5vh"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            name={"postalCodePaymentDetail"}
                            border={"1px solid #000000"}
                            borderRadius={"5px"}
                          />
                        </div>
                        <div
                          style={{
                            width: isMobileSize ? "100%" : "42.2%",
                            paddingTop: isMobileSize ? "1em" : "0",
                          }}
                        >
                          <div className="inputLabel">Town/city</div>
                          <InputText
                            placeholder={"Fill in town/city"}
                            width={"100%"}
                            height={isMobileSize ? "37.5px" : "5vh"}
                            fontSize={isMobileSize ? "14px" : "1vw"}
                            name={"townOrCityPaymentDetail"}
                            border={"1px solid #000000"}
                            borderRadius={"5px"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  <div className="askPromoCodeContainer">
                    <div className="askPromoCodeLabelContainer">
                      Have a promo code/discount?
                    </div>
                    <div
                      style={{
                        marginTop:
                          validPromoCode || validPromoCode === false
                            ? "1.4em"
                            : "",
                      }}
                    >
                      <InputText
                        placeholder={"Enter gift card or promo code"}
                        width={isMobileSize ? "50vw" : "20vw"}
                        height={"5vh"}
                        name={"promoCode"}
                        border={
                          validPromoCode === false
                            ? "1px solid #B6783F"
                            : "1px solid #000000"
                        }
                        backgroundColor={
                          validPromoCode === false ? "#FFF4EA" : ""
                        }
                        fontSize={isMobileSize ? "3vw" : "1vw"}
                        borderRadius={"5px"}
                        setter={setPromoCode}
                      />
                      {validPromoCode ? (
                        <div
                          className="couponStyle"
                          style={{
                            color: "#08CAA7",
                          }}
                        >
                          Coupon applied
                        </div>
                      ) : validPromoCode === false ? (
                        <div
                          className="couponStyle"
                          style={{ color: "#B6783F" }}
                        >
                          Coupon invalid
                        </div>
                      ) : null}
                    </div>
                    {validPromoCode ? (
                      <div className="validIconContainer">
                        <img src={ValidIcon} alt="valid-icon" />
                      </div>
                    ) : null}
                    <div
                      className="applyPromoCodeBtn"
                      style={{
                        cursor: promoCode ? "pointer" : "",
                        backgroundColor: promoCode ? "#000000" : "#9e9e9e",
                      }}
                    >
                      <div
                        style={{ color: promoCode ? "#FFFFFF" : "#000000" }}
                        onClick={() => handleCheckPromoCode()}
                      >
                        Apply
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {
              isOpenSlideTwo ? (
                <div
                  className="orderSummaryMainContainer"
                  style={{ marginTop: isMobileSize ? "2em" : "0" }}
                >
                  <div className="orderSummaryContainer">
                    <div className="orderSummaryTitle">Order Summary</div>
                    <div className="orderContainer">
                      <div className="orderDescLabel">Subtotal <br /><span className="text-xs">{totalPrice?.discount_price ? '(before disc)' : ''}</span></div>
                      <div className="orderDescValue">{`${searchState &&
                        searchState.currencyVal &&
                        searchState.currencyVal.code
                        } ${totalPrice?.discount_price ? totalPriceBeforeDiscount?.totalPrice ? formatPrice(totalPriceBeforeDiscount?.totalPrice) : "" : totalPrice && totalPrice.product_price
                          ? formatPrice(totalPrice.product_price)
                          : 0
                        }`}</div>
                    </div>
                    {
                      !totalPrice?.discount_price && (
                        <div className="orderContainer">
                          <div className="orderDescLabel">Product tax</div>
                          <div className="orderDescValue">{`${searchState &&
                            searchState.currencyVal &&
                            searchState.currencyVal.code
                            } ${totalPrice && totalPrice.product_tax
                              ? formatPrice(totalPrice.product_tax)
                              : 0
                            }`}
                          </div>
                        </div>
                      )
                    }
                    {
                      totalPrice?.discount_price > 0 && (
                        <>
                          <div className="orderContainer">
                            <div className="orderDescLabel"><span className="font-bold">Discount</span></div>
                            <div className="orderDescValue">
                              <span className="font-bold">
                              {
                                totalPrice && totalPrice.discount_price > 0 ? `- ${searchState &&
                                  searchState.currencyVal &&
                                  searchState.currencyVal.code
                                  } ${totalPrice && totalPrice.discount_price
                                    ? formatPrice(totalPrice.discount_price)
                                    : 0
                                  }`
                                  : '-'
                              }
                              </span>
                            </div>
                          </div>
                          <div className="orderContainer">
                            <div className="orderDescLabel">Subtotal <br /><span className="text-xs">(after disc)</span></div>
                            <div className="orderDescValue">{`${searchState &&
                              searchState.currencyVal &&
                              searchState.currencyVal.code
                              } ${totalPrice && totalPrice.product_price
                                ? formatPrice(totalPrice.product_price)
                                : 0
                              }`}
                            </div>
                          </div>
                          <div className="orderContainer">
                            <div className="orderDescLabel">Product tax <br /><span className="text-xs">(after disc)</span></div>
                            <div className="orderDescValue">{`${searchState &&
                              searchState.currencyVal &&
                              searchState.currencyVal.code
                              } ${totalPrice && totalPrice.product_tax
                                ? formatPrice(totalPrice.product_tax)
                                : 0
                              }`}
                            </div>
                          </div>
                        </>
                      )
                    }
                    <div className="orderContainer">
                      <div className="orderDescLabel">Transaction fee</div>
                      <div className="orderDescValue">{`${searchState &&
                        searchState.currencyVal &&
                        searchState.currencyVal.code
                        } ${totalPrice && totalPrice.transaction_fee
                          ? formatPrice(totalPrice.transaction_fee)
                          : 0
                        }`}</div>
                    </div>
                    <div className="orderContainer">
                      <div className="orderDescLabel">Transaction tax</div>
                      <div className="orderDescValue">{`${searchState &&
                        searchState.currencyVal &&
                        searchState.currencyVal.code
                        } ${totalPrice && totalPrice.transaction_tax
                          ? formatPrice(totalPrice.transaction_tax)
                          : 0
                        }`}</div>
                    </div>
                    <div className="orderContainer">
                      <div className="orderDescLabel">Shipping fee</div>
                      <div className="orderDescValue">
                        {`${searchState &&
                          searchState.currencyVal &&
                          searchState.currencyVal.code
                          } ${totalPrice && totalPrice.shipping_cost
                            ? formatPrice(totalPrice.shipping_cost)
                            : 0
                          }`}
                      </div>
                    </div>
                    {/* {
                      totalPrice && totalPrice.discount_price > 0 && (
                      )
                    } */}
                    {/* {shoppingCarts &&
                  Array.isArray(shoppingCarts) &&
                  shoppingCarts.length > 0 &&
                  validPromoCode ? (
                    <div className="orderContainer">
                      <div className="orderDescLabel">
                        <div
                          className="discountLabel"
                          style={{ color: "#B6783F" }}
                        >
                          Discount
                        </div>
                        <div
                          className="discountLabel"
                          style={{
                            color: "#9E9E9E",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                        >{`CODE: ${totalPrice.promoCode}`}</div>
                      </div>
                      <div
                        className="orderDescValue"
                        style={{ color: "#B6783F", marginTop: "-1.25em" }}
                      >{`- ${
                        searchState &&
                        searchState.currencyVal &&
                        searchState.currencyVal.code
                      } ${totalPrice.couponDiscount}`}</div>
                    </div>
                  ) : null} */}
                  </div>
                  <div className="orderSummaryTotalContainer">
                    <div className="totalItemsOrderedContainer">
                      {totalPrice &&
                        totalPrice.totalItems &&
                        totalPrice.totalItems > 1
                        ? `${totalPrice.totalItems} items `
                        : `${totalPrice.totalItems} item `}
                      in cart
                    </div>
                    <div className="dividerOrderSummary"></div>
                    <div className="totalItemsOrderedContainer">
                      <div className="totalOrderDescLabel">Total</div>
                      <div className="totalOrderDescLabel">{`${searchState &&
                        searchState.currencyVal &&
                        searchState.currencyVal.code
                        } ${totalPrice && totalPrice.total_price
                          ? formatPrice(totalPrice.total_price)
                          : 0
                        }`}</div>
                    </div>
                    {shoppingCarts &&
                      Array.isArray(shoppingCarts) &&
                      shoppingCarts.length > 0 ? (
                      <div className="includeSaleTax">Incl. sales tax</div>
                    ) : null}
                  </div>
                </div>
              ) : null
            }
          </div >
          {!isOpenSlideTwo ? (
            <div
              className="btnContinueToPaymentContainer"
              onClick={() => handleOnClickContinueToPaymentBtn()}
              style={
                isMobileSize ? {
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "fixed",
                  right: "0",
                  bottom: "0",
                  backgroundColor: "white",
                  height: "10vh",
                  margin: "0",
                  zIndex: "10"
                }
                  : null
              }
            >
              <div className="btnContinueToPayment">
                <div>Continue to payment</div>
              </div>
            </div>
          ) : (
            <div className="btnContinueToPaymentContainer">
              <div
                className="btnContinueToPayment"
                onClick={() =>
                  Object.values(orderSummaryParams)?.every(item => item) && paymentChannelId
                    ? handleOnClickPayNowBtn()
                    : null
                }
                style={{
                  backgroundColor:
                    Object.values(orderSummaryParams)?.every(item => item) && paymentChannelId
                      ? "#000000"
                      : "#D9D9D9",
                }}
              >
                <div>Pay now</div>
              </div>
            </div>
          )}
        </div >
      );
    } else {
      return (
        <div className="shoppingCartMainContainer">
          <div className="shoppingCartContainer">
            <div className="shoppingCartAndContactInformationAndShippingAddressContainer">
              <div className="shoppingCartMenu">Shopping Cart</div>
              <div className="emptyShoppingCartContainer">
                <img
                  src={EmptyShoppingCartIcon}
                  alt="empty-shopping-cart-icon"
                />
                <div className="emptyShoppingCartLabel">
                  Your cart is currently empty
                </div>
                <div
                  className="emptyShoppingCartShopNowLabel"
                  onClick={() => handleClickStartShoppingNow()}
                >
                  Start shopping now
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default ShoppingCart;
